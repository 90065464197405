<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col>
        <ecommerce-statistics :data="statisticsItems" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <EcommerceRevenueReport
          :data="data.revenue"
          :revenuereportm="revenueReport"
          :revenueyears="revenueYears"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import axios from "@axios";
export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
    EcommerceRevenueReport,
  },
  data() {
    return {
      data: {},
      revenueReport: null,
      revenueYears: null,
      statisticsUserCommand: null,
      totalCommandToday: null,
      statisticHourMonth: null,
      todayHour: null,
      dateApi: null,
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "",
          subtitle: "総ユーザー数",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-info",
          title: "",
          subtitle: "総売上",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "TrendingUpIcon",
          color: "light-danger",
          title: "",
          subtitle: "今月のユーザーサインアップ",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-success",
          title: "",
          subtitle: "今月の収益",
          customClass: "",
        },
      ],
    };
  },
  async created() {
    // data
    await this.getData();
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;
      console.log(this.data);
      const userData = getUserData();
      // this.data.congratulations.name =
      //   userData.fullName.split(" ")[0] || userData.username;
    });
  },
  methods: {
    async getData() {
      let res = await axios.get("Dashboard");
      if (res.status == 200) {
        let data = res.data.data;
        this.statisticsItems[0].title = data.totalUsers;
        this.statisticsItems[1].title = data.totalAmountEarned;
        this.statisticsItems[2].title = data.totalUsersInMonth;
        this.statisticsItems[3].title = res.data.data.totalAmountEarnedInMonth;
        this.revenueReport = data.revenueReport;
        this.revenueYears = data.revenueYears;
        // this.totalCommandToday = data.userCommandToday;
        // this.statisticsUserCommand = data.statisticUsersCommand;
        // this.statisticHourMonth = data.statisticHourMonth;
        // this.todayHour = data.totalHourToday;
      }
      // console.log(res)
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
