<template>
  <div>
    <b-card v-if="data" no-body class="card-revenue-budget">
      <b-row class="mx-0">
        <b-col md="8" class="revenue-report-wrapper">
          <div
            class="d-sm-flex justify-content-between align-items-center mb-3"
          >
            <h4 class="card-title mb-50 mb-sm-0">収益レポート</h4>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-2">
                <span
                  class="
                    bullet bullet-primary
                    svg-font-small-3
                    mr-50
                    cursor-pointer
                  "
                />
                <span>月間収益のチャート</span>
              </div>
            </div>
          </div>

          <!-- chart -->
          <vue-apex-charts
            ref="realtimeChart"
            id="revenue-report-chart"
            type="bar"
            height="230"
            :options="revenueReport.chartOptions"
            :series="seri"
          />
        </b-col>

        <b-col md="4" class="budget-wrapper">
          <!-- <b-dropdown
          text="2020"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item v-for="year in data.years" :key="year">
            {{ year }}
          </b-dropdown-item>
        </b-dropdown> -->
          <b-form-select
            style="width: auto"
            v-model="currentYear"
            :options="options"
            class="mb-3"
            value-field="value"
            text-field="text"
          ></b-form-select>
          <h2 class="mb-25">{{ revenueyears[currentYear] }}</h2>
          <!-- <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Budget:</span>
          <span>56,800</span>
        </div> -->
          <vue-apex-charts
            ref="realtimeChart3"
            id="budget-chart"
            type="line"
            height="80"
            :options="budgetChart.options"
            :series="seri"
          />
          <p class="card-text font-small-4 mb-0">
            年間売上
          </p>
          <!-- 
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          Increase Budget
        </b-button> -->
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="data" no-body class="card-revenue-budget">
      <b-row class="mx-0">
        <b-col md="12" class="revenue-report-wrapper">
          <div
            class="d-sm-flex justify-content-between align-items-center mb-3"
          >
            <h4 class="card-title mb-50 mb-sm-0">
              <b-form-select
                style="width: auto"
                v-model="selectMonth1"
                :options="optionsMonth1"
                class="mr-1"
                value-field="value"
                text-field="text"
              ></b-form-select>
              <b-form-select
                style="width: auto"
                v-model="selectYear1"
                :options="optionsYear1"
                class=""
                value-field="value"
                text-field="text"
              ></b-form-select>
            </h4>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-2">
                <span
                  class="
                    bullet bullet-primary
                    svg-font-small-3
                    mr-50
                    cursor-pointer
                  "
                />
                <span>1日あたりのツール使用人数集計のチャート</span>
              </div>
            </div>
          </div>

          <!-- chart -->
     
          <vue-apex-charts
            ref="realtimeChart2"
            id="revenue-report-chart"
            type="bar"
            height="300"
            :options="revenueReport.chartOptions2"
            :series="seri2"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormSelect,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import { props } from "vue-prism-component";
import axios from "@axios";

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormSelect,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    revenuereportm: {
      type: Object,
    },
    revenueyears: {
      type: Object,
    },
    // totalusercommandtoday: {
    //   type: Number,
    // },
    // statisticsusercommand: {
    //   type: Object,
    // },
    // statistichourmonth:{
    //     type: Object
    // },
    // todayhour :{
    //   type: Number
    // }
  },
  watch: {
    revenuereportm(newVal) {
      if (newVal) {
        var opts = Object.keys(newVal);
        this.options = opts.map((x) => ({ value: x, text: x }));
        this.currentYear = opts[opts.length - 1];
        this.seri[0].data = newVal[this.currentYear];
      }
    },
    revenueyears(newVal) {
      if (newVal) {
      }
    },
    currentYear(newVal) {
      if (this.revenuereportm) {
        this.seri[0].data = this.revenuereportm[this.currentYear];
        this.$refs.realtimeChart.updateSeries(
          [
            {
              data: this.seri[0].data,
            },
          ],
          false,
          true
        );
        // this.$refs.realtimeChart3.updateSeries(
        //   [
        //     {
        //       data: this.seri[0].data,
        //     },
        //   ],
        //   false,
        //   true
        // );
      }
    },
    selectYear1: async function (val) {
      if (this.flag1) {
        await this.getData();
      }
    },
    selectMonth1: async function (val) {
      if (this.flag1) {
        await this.getData();
      }
    },
    // statisticsusercommand(newVal) {
    //   if(newVal){
    //     var opts = Object.keys(newVal);
    //     this.options2 = opts.map((x) => ({ value: x, text: x }));
    //     this.currentYear2 = opts[opts.length - 1];
    //     this.seri2[0].data = newVal[this.currentYear];
    //   }
    // },
    //  currentYear2(newVal) {
    //   if (this.statisticsusercommand) {
    //     this.seri2[0].data = this.statisticsusercommand[this.currentYear];
    //      this.$refs.realtimeChart2.updateSeries([{
    //     data: this.seri2[0].data,
    //   }], false, true);
    //   }
    // },
    // statistichourmonth(newVal){
    //   if(newVal){
    //     var opts = Object.keys(newVal);
    //     this.options3 = opts.map((x) => ({ value: x, text: x }));
    //     this.currentYear3 = opts[opts.length - 1];
    //     this.seri3[0].data = newVal[this.currentYear];
    //   }
    // },
    // currentYear3(newVal) {
    //   if (this.statisticsusercommand) {
    //     this.seri3[0].data = this.statisticsusercommand[this.currentYear];
    //      this.$refs.realtimeChartMonth.updateSeries([{
    //     data: this.seri3[0].data,
    //   }], false, true);
    //   }
    // },
  },
  methods: {
    async getData() {
      let res = await axios.get(
        `Dashboard/user-use-tool/${this.selectMonth1}/${this.selectYear1}`
      );
      if (res.status == 200) {
        console.log(res.data.data);
        this.seri2[0].data = res.data.data;

        if (this.flag1) {
          this.$refs.realtimeChart2.updateSeries(
            [
              {
                data: this.seri2[0].data,
              },
            ],
            false,
            true
          );
        }
        this.flag1 = true;
      }
    },
  },
  async mounted() {
    let date = new Date();
    this.currentYear = date.getFullYear();
    this.selectYear1 = date.getFullYear();
    this.selectMonth1 = date.getMonth() + 1;
    for (let i = 2021; i <= this.selectYear1; i++) {
      this.optionsYear1.push(i);
    }
    await this.getData();
  },
  data() {
    return {
      currentYear: 2021,
      currentYear2: 2021,
      currentYear3: 2021,
      selectYear1: 2022,
      selectMonth1: 1,
      optionsMonth1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      optionsYear1: [],
      options2: [],
      options3: [],
      flag1: false,
      seri: [
        {
          name: "Earning",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      seri2: [
        {
          name: "Earning2",
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0,
          ],
        },
      ],
      seri3: [
        {
          name: "Earning3",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      earnedYear: null,
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return value.toLocaleString("ja-JP", {
                  style: "currency",
                  currency: "JPY",
                });
              },
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
        chartOptions2: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
              labels: {
                formatter: function (val) {
                  return val.toFixed(0);
                },
              },
            },
          },
          xaxis: {
            categories: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
              "31",
            ],
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return Math.ceil(value);
              },
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            sparkline: { enabled: true },
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, "#dcdae3"],
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },
};
</script>
