<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>集計</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in data"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon v-if="index == 0 || index == 2" size="24" :icon="item.icon" />
                <svg version="1.1" v-if="index == 1 || index == 3" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="24px" height="24px" viewBox="0 0 471.208 471.207" style="enable-background:new 0 0 471.208 471.207;"
	 xml:space="preserve">
<g>
	<path d="M407.4,299.198c0,9.906-8.027,17.927-17.934,17.927H267.61v125.917c0,15.551-12.613,28.165-28.171,28.165
		c-15.554,0-28.167-12.614-28.167-28.165V317.125H81.728c-9.897,0-17.921-8.021-17.921-17.927c0-9.901,8.023-17.928,17.921-17.928
		h129.538v-49.834l-1.017-1.383H81.728c-9.897,0-17.921-8.027-17.921-17.928c0-9.903,8.023-17.921,17.921-17.921h101.938
		L72.98,44.952c-9.265-12.498-6.656-30.136,5.843-39.413c12.492-9.256,30.142-6.646,39.413,5.852l120.52,162.52L352.589,12.771
		c8.967-12.706,26.562-15.738,39.253-6.756c12.714,8.976,15.74,26.561,6.762,39.266L293.393,194.205h96.073
		c9.906,0,17.934,8.018,17.934,17.921c0,9.901-8.027,17.928-17.934,17.928H268.071l-0.461,0.65v50.561h121.856
		C399.373,281.271,407.4,289.297,407.4,299.198z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  data(){
    return {
      year: 2021,
      listYear: [],
    }
  },
  created(){
     let currentYear = new Date().getFullYear();
    
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
};
</script>
